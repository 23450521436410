define("@ember/legacy-built-in-components/mixins/_target_action_support", ["exports", "@ember/legacy-built-in-components/components/_internals", "@ember/object", "@ember/object/mixin", "@ember/debug"], function (_exports, _internals, _object, _mixin, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; } /* eslint-disable ember/no-get */ /* eslint-disable ember/no-new-mixins */ /**
                                                                                                                                                                                                                                                                     @module ember
                                                                                                                                                                                                                                                                     */
  /**
  `Ember.TargetActionSupport` is a mixin that can be included in a class
  to add a `triggerAction` method with semantics similar to the Handlebars
  `{{action}}` helper. In normal Ember usage, the `{{action}}` helper is
  usually the best choice. This mixin is most often useful when you are
  doing more complex event handling in Components.
  
  @class TargetActionSupport
  @namespace Ember
  @extends Mixin
  @private
  */
  var _default = _mixin.default.create({
    target: null,
    action: null,
    actionContext: null,
    actionContextObject: (0, _object.computed)('actionContext', function () {
      var actionContext = (0, _object.get)(this, 'actionContext');
      if (typeof actionContext === 'string') {
        var value = (0, _object.get)(this, actionContext);
        if (value === undefined) {
          value = (0, _object.get)(_internals.context.lookup, actionContext);
        }
        return value;
      } else {
        return actionContext;
      }
    }),
    /**
    Send an `action` with an `actionContext` to a `target`. The action, actionContext
    and target will be retrieved from properties of the object. For example:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      action: 'save',
      actionContext: alias('context'),
      click() {
        this.triggerAction(); // Sends the `save` action, along with the current context
                              // to the current controller
      }
    });
    ```
     The `target`, `action`, and `actionContext` can be provided as properties of
    an optional object argument to `triggerAction` as well.
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      click() {
        this.triggerAction({
          action: 'save',
          target: this.get('controller'),
          actionContext: this.get('context')
        }); // Sends the `save` action, along with the current context
            // to the current controller
      }
    });
    ```
     The `actionContext` defaults to the object you are mixing `TargetActionSupport` into.
    But `target` and `action` must be specified either as properties or with the argument
    to `triggerAction`, or a combination:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      click() {
        this.triggerAction({
          action: 'save'
        }); // Sends the `save` action, along with a reference to `this`,
            // to the current controller
      }
    });
    ```
     @method triggerAction
    @param opts {Object} (optional, with the optional keys action, target and/or actionContext)
    @return {Boolean} true if the action was sent successfully and did not return false
    @private
    */
    triggerAction: function triggerAction() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var action = opts.action,
        target = opts.target,
        actionContext = opts.actionContext;
      action = action || (0, _object.get)(this, 'action');
      target = target || getTarget(this);
      if (actionContext === undefined) {
        actionContext = (0, _object.get)(this, 'actionContextObject') || this;
      }
      if (target && action) {
        var ret;
        if (target.send) {
          var _target;
          ret = (_target = target).send.apply(_target, _toConsumableArray([action].concat(actionContext)));
        } else {
          var _target2;
          (false && !(typeof target[action] === 'function') && (0, _debug.assert)("The action '".concat(action, "' did not exist on ").concat(target), typeof target[action] === 'function'));
          ret = (_target2 = target)[action].apply(_target2, _toConsumableArray([].concat(actionContext)));
        }
        if (ret !== false) {
          return true;
        }
      }
      return false;
    }
  });
  _exports.default = _default;
  function getTarget(instance) {
    var target = (0, _object.get)(instance, 'target');
    if (target) {
      if (typeof target === 'string') {
        var value = (0, _object.get)(instance, target);
        if (value === undefined) {
          value = (0, _object.get)(_internals.context.lookup, target);
        }
        return value;
      } else {
        return target;
      }
    }
    if (instance._target) {
      return instance._target;
    }
    return null;
  }
});