define("ember-cli-head/components/head-layout", ["exports", "@ember/component", "@ember/service", "ember-cli-head/templates/components/head-layout"], function (_exports, _component, _service, _headLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    layout: _headLayout.default,
    document: (0, _service.inject)('-document'),
    /**
     * If true, this will tear down any existing head on init of this component.
     * This is useful if there is a head built with fastboot - it will then be torn down when this is initialized in the browser.
     * If you do not want this behavior, you can set this to false.
     * @public
     */
    shouldTearDownOnInit: true,
    /**
     * The element to render into. Defaults to <head> in `init`, overridable for our own tests only.
     * @private
     */
    headElement: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('headElement') === null) {
        this.set('headElement', this.get('document.head'));
      }
      if (this.get('shouldTearDownOnInit')) {
        this._tearDownHead();
      }
    },
    /**
     * Tear down any previous head, if there was one.
     * @private
     */
    _tearDownHead: function _tearDownHead() {
      if (this._isFastboot()) {
        return;
      }

      // clear fast booted head (if any)
      var document = this.get('document');
      var startMeta = document.querySelector('meta[name="ember-cli-head-start"]');
      var endMeta = document.querySelector('meta[name="ember-cli-head-end"]');
      if (startMeta && endMeta) {
        var el = startMeta.nextSibling;
        while (el && el !== endMeta) {
          document.head.removeChild(el);
          el = startMeta.nextSibling;
        }
        document.head.removeChild(startMeta);
        document.head.removeChild(endMeta);
      }
    },
    _isFastboot: function _isFastboot() {
      return typeof FastBoot !== 'undefined';
    }
  });
  _exports.default = _default;
});