define("ember-power-select/components/power-select/power-select-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="ember-power-select-group" aria-disabled={{if @group.disabled "true"}} role="option" aria-selected={{false}}>
    <span class="ember-power-select-group-name">{{@group.groupName}}</span>
    {{yield}}
  </li>
  */
  {
    "id": "KAeZfjYQ",
    "block": "[[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[52,[30,1,[\"disabled\"]],\"true\"]],[14,\"role\",\"option\"],[15,\"aria-selected\",false],[12],[1,\"\\n  \"],[10,1],[14,0,\"ember-power-select-group-name\"],[12],[1,[30,1,[\"groupName\"]]],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@group\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-power-select/components/power-select/power-select-group.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});