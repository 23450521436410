define("ember-cli-meta-tags/mixins/route-meta", ["exports", "@ember/runloop", "@ember/service", "@ember/object/mixin", "@ember/array"], function (_exports, _runloop, _service, _mixin, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.metaToHeadTags = metaToHeadTags;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  // Route mixin for setting head meta tags on transition into/out of route

  // @example How to set meta tags on a route
  //   ExampleRoute = Ember.Route.extend RouteMetaMixin,
  //     meta: ->
  //       return
  //         meta_property_name1: meta_value1
  //         meta_property_name2: meta_value2

  function metaToHeadTags(meta) {
    var metaTypes = Object.keys(meta);
    return metaTypes.reduce(function (headTags, meta_type) {
      return headTags.pushObjects(Object.keys(meta[meta_type]).map(function (key) {
        var _attrs;
        return {
          tagId: "".concat(meta_type, ":").concat(key),
          type: 'meta',
          attrs: (_attrs = {}, _defineProperty(_attrs, meta_type, key), _defineProperty(_attrs, "content", meta[meta_type][key]), _attrs)
        };
      }));
    }, (0, _array.A)([]));
  }
  var _default = _mixin.default.create({
    headTagsService: (0, _service.inject)('head-tags'),
    // convert legacy meta tags to headTags
    headTags: function headTags() {
      var meta = this.get('meta');
      if (typeof meta === 'function') {
        meta = meta.apply(this);
      } else if (_typeof(meta) !== 'object') {
        return undefined;
      }
      return metaToHeadTags(meta);
    },
    _collectHeadTags: function _collectHeadTags() {
      var service = this.get('headTagsService');
      (0, _runloop.next)(service, 'collectHeadTags');
    },
    actions: {
      resetMeta: function resetMeta() {
        this._collectHeadTags();
      }
    }
  });
  _exports.default = _default;
});